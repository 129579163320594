.activateSensorFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .previousButton {
    p {
      margin: 4px 0;
    }
  }
}

.dot {
  height: 8px;
  width: 8px;
  background-color: var(--color-gray-500);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.blackDot {
    background-color: black;
}

.arrowMargin {
    margin-right: 12px;
    margin-left: 12px;
}

.titleText {
  padding-top: 4px;
}

.reviewProfile {
  margin-top: 44px;
}

.profileContent {
  width: 700px;
  max-width: 1550px;
  overflow: hidden;
}

.section {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    
    h4 {
        color: var(--color-gray-700);
        width: 100%
    }
      
    // This :after style is used to display the straight gray line that goes to the right of the header for each table.
    h4:after {
      content: "";
      display: inline-block;
      height: 2px;
      vertical-align: middle;
      width: 100%;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 2px solid rgba(0, 0, 0, 0.3);
    }
}

.editProfileSection {
    h4 {
        color: black
    }
}

.sectionContainer {
    width: 50%
}

.avatar {
    width: 64px;
    height: 64px;
    margin-right: 32px;
}

.detailHeader {
    display: flex;
    align-items: center;
}

.headerDescription {
  margin-top: 28px;
}

.greyNumbers { 
    color: var(--whoop-color-gray-500);
    margin-right: 12px;
}

