@import "styles/color.scss";

.invalidMessage {
    border-left: 2px solid transparent;
    color: $BRANDING_RED;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
    height: 16px;
    margin-bottom: 12px;
  }
  
  .invalidEmailInput {
    border-color: $BRANDING_RED;
    background: rgba(255, 0, 38, 0.2);
  }