.labelSquare {
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.activated {
  background: var(--whoop-color-teal);
}

.pending {
  background: var(--whoop-color-sleep-blue);
}

.invited {
  background: var(--whoop-color-yellow);
}

.inactive {
  background: #d3d6db;
}

.gray500 {
  background: var(--color-gray-500);
}

.brandRed {
  background: var(--color-branding-red);
}

.strainBlue {
  background: var(--color-strain-blue);
}

.red {
  background: var(--whoop-color-red);
}

.yellow {
  background: var(--whoop-color-yellow);
}

.green {
  background: var(--whoop-color-green);
}

.sleep {
  background: var(--whoop-color-sleep-blue);
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}
