$grid-line: 1px solid var(--color-black-alpha-100);

.container {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.row {
    padding: 1rem 0;
    display: flex;
    
    & > * {
        flex-grow: 1;
    }

    &:not(:first-of-type) {
        border-bottom: $grid-line;
    }
}

.dateSelector {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.radioToggle {
    background: #f5f5f5;
    border: none;
    border-radius: 8px;
    align-items: center;
    padding: 8px 12px;

    input[type="radio"]:checked + label {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        margin: 0;
        border: none;
    }
}

.breakdownTable {
    border-collapse: collapse;

    th {
        font-family: 'ProximaNova-Bold';
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: .1em;
    }

    td {
        font-family: 'DinPro';
        font-size: 15px;
    }

    th, td {
        padding: 1.25rem;
        text-align: left;
        user-select: none;
    }

    tr {
        border-left: 1rem;
        border-bottom: $grid-line;

        &.loadingRow {
            border: none;
        
            td {
                height: 4px;
                padding: 0; 
            }
        }

        &.selectable {
            cursor: pointer;

            &:hover {
                background-color: var(--whoop-color-gray-200)
            }
        }
    }

    .header {
        display: flex;
        align-items: center;

        min-height: 1.25rem;
    }
}

.summaryRow { 
    background-color: var(--whoop-color-pro-gold-alpha-100);
}

.noData {
    align-self: center;
    padding: 1.25rem;
}
