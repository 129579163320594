@import '@whoop/web-components/dist/typography.mixin';

.groupsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  .tableHeader {
    width: 25%;
    height: 113px;

    &:first-child {
      vertical-align: top;
      text-align: left;
      padding-top: 16px;
      padding-left: 24px;
      cursor: default;
    }

    .allGroupsText {
      @include p3;
    }
  }

  .headerSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tableHeaderChart {
      @include n4;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      fill: var(--whoop-color-white);
      height: 84px;
      width: 84px;
      margin-bottom: 26px;
    }

    .tableHeaderText {
      display: flex;
      justify-content: center;
      align-items: center;
      @include c2;
      font-weight: 700;
      color: var(--whoop-color-white-alpha-900);

      svg {
        height: 8px;
        width: 8px;
        margin-left: 4px;
      }
    }
  }

  .clickableRow {
    cursor: pointer;
  }

  .tableRow {
    height: 64px;
    background: var(--whoop-color-background-blue);

    td:first-child{
       border-radius: 8px 0 0 8px;
    }
    td:last-child{
      border-radius: 0 8px 8px 0;
    }

    // When no metrics, we only show name column. So if theres only 1 td element, add border to all 4 corners
    td:only-child {
      border-radius: 8px;
    }
  }

  .cell {
    @include n5;
    line-height: 20px;
    text-align: center;
    width: 25%;
  }
}
