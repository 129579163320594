@import "styles/color";
@import "styles/layout";

.controls {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  .left {
    justify-content: flex-start;
    flex-grow: 1;
    display: flex;
  }
}

.addNewHub {
  padding: 12px 16px;
  width: 136px;
  height: 40px;
  border-radius: 4px;
}


.deployApp {
  padding: 12px 16px;
  width: 146px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
}

.linkBtn {
  display: flex;

  img {
    margin-right: $SPACING-1;
  }
}


.logo {
  margin-left: 12px;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}