@import '@whoop/web-components/dist/typography.mixin';

$grid-line: 1px solid var(--color-white-alpha-100);

.container {
    display: flex;
    flex-direction: column;
}

.row {
    padding: 1rem 0;
    display: flex;
    height: 36px;
    
    & > * {
        flex-grow: 1;
    }
    
    &:not(:first-of-type) {
        border-bottom: $grid-line;
    }

    &.options {
        gap: 16px;
    }
}

.dateSelector {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.radioToggle {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    align-items: center;
    padding: 8px 12px;

    input[type="radio"]:checked + label {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        margin: 0;
        border: none;
    }
}

.breakdownTable {
    border-collapse: collapse;

    th {
        font-family: 'ProximaNova-Bold';
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: .1em;
    }

    td {
        font-family: 'DinPro';
        font-size: 15px;
    }

    th, td {
        padding: 1.25rem;
        text-align: left;
        user-select: none;
    }

    tr {
        overflow: hidden;
        color: var(--text-color);
        border-left: 1rem;
        border-bottom: $grid-line;

        &.loadingRow {
            border: none;
        
            td {
                height: 4px;
                padding: 0; 
            }
        }

        &.selectable {
            cursor: pointer;

            &:hover {
                background-color: var(--whoop-color-white-alpha-500)
            }
        }
    }

    .header {
        display: flex;
        align-items: center;

        min-height: 1.25rem;
    }
}

.summaryRow { 
    background-color: var(--whoop-color-focus-alpha-150);
}

.noData {
    align-self: center;
    padding: 1.25rem;
    color: var(--text-color);
}

.filterDropdown {
  flex: 0 0 140px;
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.capitalize p {
    text-transform: capitalize;
  }
}
