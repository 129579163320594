.sectionInfo {
    display: flex;
    width: 70vw;
}

.details {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: rgba(0, 0, 0, 0.5);
    width: 600px;
}

.avgSection {
    width: 168px;
    margin-top: -44px;
    margin-left: auto;
}

.avgDetails {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
}

.avgValues {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.trend {
    display: flex;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    align-items: center;
    margin-left: 16px;
    padding: 4px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 8px;
}

.trendIcon {
    height: 8px;
    width: 16px;
}

.trendPercentSign {
    margin-top: 8px;
}