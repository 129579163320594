@import "styles/theming.scss";

.teamsContainer {
  flex-grow: 1;
  background-color: var(--background-color);
  
  .header {
    height: 68px;
    background-color: var(--background-color);
    color: var(--text-color);
  }

  .breadcrumbsRow {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
