.activeItem {
    .itemLabel {
        font-family: var(--whoop-font-family-bold);
        margin-left: 28px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.inactiveItem {
    .itemLabel {
        margin-left: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.itemLabel {
    font-family: var(--whoop-font-family-normal);
    cursor: pointer;
    text-transform: capitalize;
}


.checkContainer {
    width: 25%;
}