@import "styles/color.scss";

:export {
  strainBlue: var(--whoop-color-strain-blue);
}

.warning {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 8px 18px;
  margin: 32px 0;
  background-color: rgba(0, 147, 231, 0.2);
}
