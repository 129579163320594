@import '@whoop/web-components/dist/typography.mixin';

.leaderboardTable {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  table-layout: fixed;

  .tableHeaderRow {
    height: 28px;
    position: sticky;
    background-color: var(--color-background-blue);
    top: 0;
  }

  .tableHeader {
    text-align: right;
    width: 36px;

    &:first-of-type {
      text-align: left;
    }
  }

  .tableHeaderText {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include c2;
    font-weight: 700;
    color: var(--whoop-color-white-alpha-900);

    svg {
      height: 8px;
      width: 8px;
      margin-left: 2px;
    }
  }

  .tableHead, .tableBody, .tableRow {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .tableBody {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
    width: 100%;
  }

  .leftAlign {
    justify-content: flex-start;
  }

  .tableRow {
    display: table;
    width: 100%;
    height: 36px;

    &.clickRow {
      cursor: pointer;
    }
  }

  .widgetCell {
    text-align: right;
  }
}
