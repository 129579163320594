@import "styles/color";
@import "styles/layout";

.numberInput {
  display: flex;
  justify-content: center;

  ul {
    width: 100%;
  }
}

.tableActions {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .dropdowns {
    display: flex;

    .dropdownBtn {
      padding-left: 16px;
      padding-right: 16px;
      height: 100%;
    }

    .linkBtn {
      .logo {
        margin-left: 12px;
        width: 10px;
        height: 10px;
        margin-top: 2px;
      }
    }
  }

  .buttons {
    display: flex;

    .addNewMembers {
      padding: 12px 16px;
      border-radius: 4px;
      width: 168px;
      height: 40px;
    }

    .archiveMember {
      padding: 12px 16px;
      border-radius: 4px;
      width: 164px;
      height: 40px;
    }

    .assignNewSensor {
      padding: 12px 16px;
      width: 188px;
      height: 40px;
      border-radius: 4px;
      margin-right: 16px;
    }
  }
}

.linkBtn {
  display: flex;

  img {
    margin-right: $SPACING-1;
  }
}

.hubMembersTable {
  margin-top: 16px;
}
