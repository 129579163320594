.membershipNumber {
    font-family: 'DINPro';
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    color: var(--whoop-color-black);
}

.membershipNumbers {
    display: flex;
    flex-direction: row;
}

.totalMemberships {
    font-family: 'DINPro';
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.3);
    margin-top: auto;
    margin-left: 4px;
    margin-bottom: 4px;
}
  
.labelGroup {
    margin-bottom: 40px;
}

.rightMargin {
    margin-right: 40px
}