.container {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 24px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
}

.sectionTitle {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #212121;
    margin-bottom: 28px;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    text-align: middle;
    width: 70vw;
}

.icon {
    margin-left: 8px;
}

.chart {
    margin-top: 24px;
    height: 320px;
    width: 70vw;
}

.chartLabel {
    margin-top: 12px;
    margin-bottom : 24px;
    display: flex;
    flex-direction: row;
    width: 70vw;
}

.warning {
    width: 70vw;
    div {
        justify-content: center;
        flex-grow: 1;
    }
}

.lockedSection {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    & svg {
        color: rgba(0, 0, 0, 0.7);
    }

    & > * {
        margin: 0;
    }

    & h4 {
        text-transform: uppercase;
    }
}
