.tooltipBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    width: 250px;
    height: 40px;
    background: var(--whoop-color-black);
    border-radius: 4px;
    color: white;
    font-family: var(--whoop-font-family-normal);
}

.tooltipBox:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-top: solid 8px var(--whoop-color-black);
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
}

.tooltipDateText {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.tooltipDescriptionText {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row
}

.tooltipLine {
    display: flex;
    margin-top: 4px;
}

.tooltipValueText {
    font-family: 'DINPro';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    margin-left: 58px;
}

.activationBlock {
    width: 8px;
    height: 8px;
    margin-top: 4px;
    margin-right: 8px;
}

.active {
    background: var(--whoop-color-teal);
}

.pending {
    background: var(--whoop-color-sleep-blue);
}

.invited {
    background: var(--whoop-color-yellow);
}

.inactive {
    background: #D3D6DB;
}
