.membershipActivationLabels {
    display: flex;
    flex-direction: row;
    width: 70vw;
    justify-content: left;
}

.memberActivationsChart {
    height: 48px;
    width: 70vw;
}
