.activityIcon {
  color: var(--whoop-color-white);
}

.clickableAnnotation {
  cursor: pointer;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  font-size: 12.64px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  .durationText {
    font-family: "DINPro";
    font-size: 17px;
    line-height: 21px;
  }
}
