.hubDetailsHeader {
  display: flex;
  text-transform: capitalize;
  margin-top: var(--spacing-lg);
  flex-direction: column;
  gap: var(--spacing-xs-5);
  padding-left: 8px;

  .titleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .macAddress {
      height: 28px;

      div {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  .invitedCollaboratorsContainer {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--color-black-alpha-100);
    width: 100%;
    padding-bottom: 28px;
    margin-top: 16px;

    p {
      font-family: ProximaNova;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: var(--color-black-alpha-700);
    }

    svg {
      width: 16px;
      height: 16px;
      margin-top: 10px;
      margin-right: 12px;
    }
  }
}
