.groupDetailsViewContainer {
  color: var(--text-color);
}

.transition {
  transition: all .5s;
}

.tempTableWidth {
  width: 376px;
  overflow: auto;
  height: 200px;
}