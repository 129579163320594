.container {
    width: 100%;
    height: 67px;
    display: flex;
    border-bottom: 1px solid #EBEBEB;
    align-items: center;
    position: sticky;
    top: 0;
    background: white;
    z-index: 8;
}

.tabs {
    margin-left: 24px;
    display: flex;
    height: 100%;
}

.groupsDropdown {
    display: flex;
    margin-left: auto;
    margin-right: 32px;
}

.tab {
    margin-right: 28px;
    cursor: pointer;
    height: 65px;
    display: flex;
    align-items: center;
}

.activeTab {
    border-bottom: 2px solid #000000;
}

.dropdownIcon {
    width: 10px;
    margin-left: 16px;
    margin-bottom: 2px;
}

.dropdownButton {
    display: flex;
    align-items: center;
}

.activeIcon {
    width: 12px;
    height: 10px;
    margin-right: 12px;
}

.dropdownItemLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #444444;
    margin-bottom: 8px;
    margin-top: 8px;
}

.activeItem {
    .dropdownItemLabel {
        font-family: var(--whoop-font-family-bold);
        margin-left: 0px;
    }
}
.inactiveItem {
    .dropdownItemLabel {
        margin-left: 25px;
    }
}
