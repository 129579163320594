[data-theme="light"] {
  --text-color: var(--whoop-color-black);
  --background-color: var(--whoop-color-white);
  --background-color-hover: var(--$GRAY_200);
  --border-color: var(--whoop-color-black-alpha-500);
  --svg-color: var(--whoop-color-black);
  --search-background-color: var(--whoop-color-black-alpha-100);
  --search-background-color-hover: var(--whoop-color-black-alpha-200);
  --search-placeholder: #757575;
  --search-border: var(--whoop-color-black-alpha-500);
  --tab-text-color: var(--whoop-color-black);
  --date-picker-button-background: var(--whoop-color-white);
  --date-picker-text: var(--whoop-color-black);
  --date-picker-background: var(--whoop-color-white);
  --date-picker-grey-text: var(--whoop-color-black-alpha-500);
  --date-picker-border-line: var(--whoop-color-black-alpha-100);
  --date-picker-day-hover: var(--whoop-color-black-alpha-50);
}

[data-theme="dark"] {
  --text-color: var(--whoop-color-white);
  --background-color: var(--whoop-color-background-darkblue);
  --background-color-hover: var(--whoop-color-white-alpha-50);
  --border-color: var(--whoop-color-white-alpha-500);
  --svg-color: var(--whoop-color-white);
  --search-background-color: var(--whoop-color-white-alpha-100);
  --search-background-color-hover: var(--whoop-color-white-alpha-200);
  --search-placeholder: var(--whoop-color-white-alpha-500);
  --search-border: var(--whoop-color-white-alpha-500);
  --tab-text-color: var(--whoop-color-gray-700);
  --date-picker-button-background: var(--whoop-color-white-alpha-100);
  --date-picker-text: var(--whoop-color-white-alpha-900);
  --date-picker-background: var(--whoop-color-background-blue);
  --date-picker-grey-text: var(--whoop-color-white-alpha-500);
  --date-picker-border-line: var(--whoop-color-white-alpha-100);
  --date-picker-day-hover: var(--whoop-color-white-alpha-50);
}