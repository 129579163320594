@import "styles/color";
@import "styles/layout";

.container {
  display: flex;
  width: 100%;
  height: 36px;
  padding-left: 8px;
  background: var(--search-background-color);
  border-radius: 8px;

  &.border {
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }

  &:hover {
    background: var(--search-background-color-hover);
  }

}

.globalSearch {
  height: 100%;
  width: calc(80%);
  border: none;
  outline: none;
  padding: 0;
  padding-left: 18px;
  font-family: ProximaNova;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  background: unset;
  color: var(--text-color);
}

.globalSearch::placeholder {
  color: var(--search-placeholder);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
}

.searchIcon {
  height: 20px;
  width: 100%;

  color: var(--svg-color);
}
