.setup {
    display: flex;
    justify-content: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        width: 100%;
    }

    select,
    input,
    textarea {
        display: flex;
        width: 56.25rem;
    }

    .dropdowns {
        display: flex;
        justify-content: space-between;

        select,
        input {
            width: 26.5rem;
        }
    }
}