@import '@whoop/web-components/dist/typography.mixin';

.widgetContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: var(--color-background-blue);
  border-radius: 12px;
  margin-bottom: 24px;
}

.expandedSpacing {
  padding: 16px 16px 0px 16px;
  box-sizing: border-box;
}

.loading {
  padding: 16px 16px 0px 16px;
  height: 130px;
  box-sizing: border-box;
}

.expandableCardContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .expandedContent {
    display: flex;

    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
  }

  .condensedContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    box-sizing: border-box;
    width: 100%;

    // we want our widgets to all have the same height regardless of the content inside them
    // i.e. the strain breakdown graph is larger than the other pillars, but the widget height is still uniform
    // in order to achieve this the height of the content under the header must be defined
    &.messageHeight {
      height: 240px;
    }
    &.contentHeight {
      height: 452px;
    }

    .breakdownChart {
      display: flex;
      padding: 16px;

      box-sizing: border-box;
      height: 100%;
      background-color: var(--color-black-alpha-200);
    }
  }
}

.expandedGraph {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0px 16px 16px;
}

.leaderboard {
  overflow: hidden;
  box-sizing: border-box;
  margin: 16px 16px 0px 16px;
  flex-grow: 1;
}

.loadingLeaderboard {
  margin: 16px;
}

.gradient::after {
  content: '';
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  // rgb(26, 34, 39, 0) is the same as var(--color-background-blue) but with the opacity set to 0
  background-image: linear-gradient(180deg, rgb(26, 34, 39, 0) 0%, var(--color-background-blue) 100%);
}

.message {
  padding: 12px 16px;
  box-sizing: border-box;
  height: 100%;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .icon {
    color: var(--color-white-alpha-500);
    height: 28px;
    width: 28px;
  }

  .messageText {
    font-weight: 600;
    color: var(--color-white-alpha-500);
    @include p5;
  }
}