.pageBackground {
  color: var(--text-color);
  flex-grow: 1;
}

.headerLoading {
  padding-top: 8px;
  height: 129px;
  padding-bottom: 8px;
}

.rowLoading {
  height: 64px;

  &:not(:last-child) {
    padding-bottom: 8px;
  }
}

.errorMessage {
  // Design calls for 20px but table has 8px border-spacing
  margin-bottom: 12px;
}
