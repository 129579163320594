@import "styles/color";
@import "styles/layout";

.tableContainer {
  background: var(--whoop-color-white);
  box-sizing: border-box;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  width: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
}

.tableHeader {
  .heading {
    margin-bottom: $SPACING-0;
  }
  .subtext {
    color: $TEXT_OVERRIDES_2;
  }
}
